@import "src/styles/_constants.scss";

.separator{
    width:100%;
    background-color: #EFEFEF;
    height:50px;
}

@media (max-width: $max-mobile) {
    .separator{
        margin-left:0;
        
    }
}