@import "src/styles/_constants.scss";
.showingDataFor{
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 1.4em;

  font-family: "Roboto",sans-serif;
  font-size:14px;
  color: $color-global-text;

  &_wrapper {
    height: 48px;
  }

  ul{
    padding-left:0px;
    list-style-type: none;
  }

  ul > li:before{
    content: "- ";
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &_toggler{
    display: inline-block;
    margin-left: 10px;

    &_icon{
      margin-left:5px;
      width:22px;
      height:22px;
      transform: rotate(90deg);
      vertical-align: middle;
      path {
        fill: $color-primary;
      }
    }
    &_iconClosed{
      margin-left:5px;
      width:22px;
      height:22px;
      transform: rotate(-90deg);
      vertical-align: middle;
      path {
        fill: $color-primary;
      }
    }
  }

  &.expanded {
    background: #443c38;
    color: white;
    z-index: 10;
  }
}
