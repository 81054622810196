.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: white;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: black, $alpha: 0.3);
  z-index: 1040;
}

.container {
  margin-top: 10px;

  &_close {
    text-align: right;
    margin-bottom: 20px;
    margin-right: 10px;

    &_content {
      display: inline;
      cursor: pointer;

      svg {
        height: 20px;
        width: 20px;
        margin-bottom: -4px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  &_searchbox {
    width: 80%;
    margin: auto;
  }
}
