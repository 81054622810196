@import "src/styles/_constants.scss";

.container {
  position: fixed;
  border-radius: 50%;
  background-color: $color-primary;
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-flow: column;
  cursor: pointer;

  width: 75px;
  height: 75px;
  bottom: 20px;
  right: 20px;

  &_hidden {
    display: none;
  }

  &_text {
    width: 70%;
    font-size: 1em;
    font-size:14px;
    font-weight: bold;
    margin: auto;
    word-break: inherit;
    text-align: center;
    font-family:"Roboto",sans-serif;
  }

  svg {
    height: 1.6em;
    vertical-align: middle;
    margin: auto;
  }
}
