@import "src/styles/_constants.scss";
.arbeidsledighetvisualization {
  // height: 360px;
  //min-height: 250px;
  // @media screen and (min-width: $max-mobile){
    &_dec{
      display:flex;
      justify-content: center;
      position: relative;

      &:hover &_tip{
        // &_tip{
        visibility: visible;
        width:15em;
        height:4em;
        border-radius:20px;
        color:white;
        display:flex;
        justify-content: center;
        z-index:20;
        left:0;
        top:20px;
        p{
          font-family:"Roboto",sans-serif;
          font-size:14px;
          align-self:center;
          margin:0;
        }
      // }
      }
      &_tooltipContainer{
        position: absolute;
        // margin-left:5px;
        bottom: 100%;
        right:-9px;
        width:10% !important;
        
      }
      &_tip{
        background-color: #363636;
        visibility: hidden;
        position: absolute;
      }
    
      p{
        margin-left:5px;
        margin-bottom:3px;
        margin-bottom:12px;
        align-self: center;
      }
    }
  // }

  &_dataSource{
    display: block;
    margin: 0 auto 20px;
    max-width: calc(233px * 5);
    position: relative;
    background: url(./icon_warning.svg) no-repeat .5em center;
    //color: $color-global-text;
    color: #cc0910;
    font-weight: bold;
    top: -30px;
    padding-left: 2.5em;
    height: 1.4em;
    line-height: 1.4em;
  }
  &_heading {
    font-size: 14px;
    font-weight: bold;
    margin-bottom:0;
    
    display:flex;
    justify-content: center;
  }

  &_percentageText{
    // font-size:30px;
    font-size:20px;
    font-family: "Roboto",sans-serif;
    font-weight: bold;
    display:inline-block;
    margin-top:5px;
    margin-bottom:5px;

    display:flex;
    justify-content: center;
    
    &_vLow{
      color: #3455DB;
    }

    &_low{
      color: #0C7120;
    }

    &_medium{
      color: #804600;
    }

    &_high{
      color: #B11030;
    }

    &_vHigh{
      color: #B50000;
    }

    &::after{
      content:'%';
      font-size:20px;
      font-weight: bold;
      margin-left: 4px;
    }
  }

  &_percentageSign{
    font-size:20px;
    display:inline-block;
    margin:0;
    color:#333;
    
  }

  &_percentageRisk{
    margin:0;
    margin-bottom:12px;
    // font-size:20px;
    font-size: 14px;
    text-transform: uppercase;
    display:flex;
    justify-content: center;
    font-weight: bold;
  }

  &_text {
    font-weight: 600;
  }
  &_percentage {
    margin-bottom: 20px;
  }
  &_noData {
    margin-bottom: 55px;
    margin-top: 20px;
  }
  &_kjonn {
    display: flex;
    width: 100%;
    &_icon {
      &_container {
        display: flex;
      }
      width: auto;
      svg {
        height: 1.5em;
      }
      margin-right: 5px;
      &_percentage {
        margin-bottom: 5px;
      }
    }
    &_container {
      width: 80%;
      &_text {
        font-weight: 500;
      }
    }
  }
  &_list {
    list-style: none;
    vertical-align: middle;
    display: flex;

    div{
      width:100%;
    }
    svg {
      height: 30px;
      width: 25px;
    }
    &_text {
      margin-top: 1px;
      margin-left: 2px;
    }
  }
}
