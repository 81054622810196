@import "src/styles/_constants.scss";

.dataSource {
  display: inline;
  text-transform: uppercase;
}

.visualizationheader {
  &_container {
    position: relative;

    &_widget {
      text-align: center;
    }

    &_content {
      margin: 18px 8px;
      display: flex;
      justify-content: center;

      &_toggle {
        display: flex;
        // justify-content: flex-end;
        min-width: 187px;
        align-items: center;
        background-color: $color-primary-light;
        border-radius: 50px;
        cursor: pointer;
        height: 48px;
        padding: 20px;
        text-transform: none;
        font-size: 0.875em;

        @media (min-width: $min-not-mobile) {
          display: none;
        }
      }
    }

    &_filtercontainer {
      @media (min-width: $min-not-mobile) {
        display: none;
      }

      margin-top:1em;
      //display:flex;
      // justify-content: flex-end;

      &_content {
        align-items: center;
        background-color: $color-primary-light;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        height: 48px;
        padding: 20px;

        &_infotext {
          font-size: 0.875em;
          color: #000;
          text-transform: none;
        }

        &_icon {
          margin-left: 5px;
        }
      }
    }

    // &_toggle {
    //   display: flex;
    //   justify-content: flex-end;
    //   align-items: center;
    //   text-transform: none;
    //   @media (min-width: $min-not-mobile) {
    //     display: none;
    //   }
    // }
    &_header {
      background-color: white;

      &__title {
        text-transform: uppercase;

        @media (min-width: $min-not-mobile) {
          margin: 0 auto;
          max-width: calc(233px * 5);
          padding: 0 .5em;
          height: max-content;
        }

        //display: flex;
        margin-left: 10px;

        h2 {
          align-self: center;
          margin-bottom: 10px;
          margin-bottom: 0;
          font-size: 1.8em;
          font-size: 1.875em;

          @media (min-width: $min-not-mobile) {
            font-size: 2em;
            font-size: 1.25em;
            font-size: 1.875em;
            //margin-left: 50px;
          }
        }

        &_filter {
          text-transform: lowercase;
          //padding-top: 10px;
          //margin-left: 10px;
          font-size: 0.875em;

          @media (min-width: $min-not-mobile) {
            font-size: 1.2em;
            font-size: 1em;
            font-size: 0.875em;
          }

          ul {
            display: inline-block;
            padding-left: 0;
            margin: 0;

            li {
              display: inline-block;
              margin-right: 3px;

              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }

        &_icon {
          &_non_widget {
            margin-left: 5px;

            @media (min-width: $min-not-mobile) {
              display: none;
            }
          }

          cursor: pointer;
          // border: 1px solid $color-primary;
          // width: 20px;
          // height: 20px;
          // border-radius: 100%;
          // position: relative;
          // margin: 14px;
          // display: inline-block;
          // vertical-align: middle;
          // min-width: 20px;
          // &:before,
          // &:after {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   right: 0;
          //   bottom: 0;
          //   background: $color-primary;
          // }
          // &:before {
          //   width: 2px;
          //   margin: 2px auto;
          // }
          // &:after {
          //   margin: auto 2px;
          //   height: 2px;
          // }
        }

        &_widget {
          text-transform: unset;
          display: inline-flex;
          margin-left: 0px;

          p,
          span {
            margin-top: 0px;
          }
        }
      }
    }

    &_modal {
      position: block;

      display: flex;
      justify-content: flex-start;
      top: 0;
      left: 0;
      right: 0;
      padding: 0.8em;
      color: white;
      background-color: #3a3a3a;
      z-index: 1040;
      width: 100%;
      height: 100%;

      &_header {
        cursor: pointer;
        position: absolute;
        right: 0;
        display: block;
        font-size: 1.2em;

        //background-color: #443c38;
        &_title {
          font-weight: 500;
          // text-transform: uppercase;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          &__desc {
            // text-transform: lowercase;
          }
        }

        &_icon {
          cursor: pointer;
          border: 1px solid $color-primary;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          position: relative;
          margin: 14px;
          vertical-align: middle;
          background: white;

          //margin-right: auto;
          //margin-left: auto;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $color-primary;
            margin: auto 2px;
            height: 2px;
          }
        }
      }

      &_filters {
        font-size: 1.1em;
        font-size: 0.875em;

        @media (min-width: $min-not-mobile) {
          justify-content: flex-start !important;
        }

        ul {
          padding-left: 0;
        }

        li {
          list-style-type: none;

          input {
            margin-right: 5px;
          }
        }

        &_header {
          font-weight: 600;
          margin-bottom: 10px;
          position: relative;

          &_icon {
            position: relative;
            margin-left: 6px;
            cursor: pointer;

            &_inner {
              color: #f89330;
            }
          }

          &_helptext {
            position: absolute;
            bottom: calc(100% + 16px);
            left: 50%;
            width: 160px;
            transform: translateX(-50%);
            background-color: white;
            display: flex;
            padding: 10px;
            border: 1px solid black;
            font-size: 0.9em;
            z-index: 1099;
            color: black;

            &::before {
              content: "";
              position: absolute;
              bottom: -14px;
              left: 50%;
              transform: translateX(-50%);
              border-style: solid;
              border-width: 14px 10px 0;
              border-color: white transparent transparent;
            }

            &_content {
              //width: 80%;
              word-break: break-word;
            }

            &_closeIcon {
              width: 20%;
            }
          }
        }
      }
    }
  }
}