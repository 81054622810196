@import "src/styles/constants.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:400,700");

.container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.btnContainer {
  margin: 10px;
}

.nivåSelection {
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 1010;
  background-color: #fbe5d0;

  @media (min-width: 1165px) {
    display: grid;
    grid-template-columns: repeat(5, 233px);
    justify-content: center;
  }

  @media (max-width: $max-mobile) {
    justify-content: flex-start;
    overflow-x: scroll;
  }

  &_cell {
    display: flex;
    flex-direction: column;
    padding: 1em calc(.5em + 10px) .5em;
    position: relative;
    flex: 0 0 $column-width - 2;
    max-width: $column-width - 2;
    font-size: 0.875em;
    margin-right: 2px;

    @media (max-width: $max-mobile) {
      flex: 0 0 $column-width-mobile;
      max-width: $column-width-mobile;
      overflow: hidden;
    }
  }
}

.selection {
  margin: 0;
  padding: 0;
  display: flex;
  position: sticky;
  top: 0px;
  z-index: 1010;
  background-color: #fbe5d0;

  @media (min-width: 1165px) {
    display: grid;
    grid-template-columns: repeat(5, 233px);
    justify-content: center;
  }

  @media (max-width: $max-mobile) {
    justify-content: flex-start;
    overflow-x: scroll;
  }

  &_cell {
    background-color: #fbe5d0;
    padding: 0.5em;
    position: relative;
    flex: 0 0 $column-width - 2;
    max-width: $column-width - 2;
    margin-right: 2px;

    @media (max-width: $max-mobile) {
      flex: 0 0 $column-width-mobile;
      max-width: $column-width-mobile;
      overflow: hidden;
    }
  }

  &_item {
    display: flex;
    background-color: white;

    &_text {
      font-weight: bold;
      font-size: 1em;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      margin: 10px;
      overflow-wrap: break-word;
      -webkit-touch-callout: none;
      /* iOS Safari */
      user-select: none;
      flex: 1 1 100%;

      &_search {
        text-transform: uppercase;
        color: grey;
      }
    }

    button {
      display: inline-grid;
      place-content: center;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      align-self: stretch;
      padding: 0 5px;

      svg {
        height: 18px;
        width: 18px;
        cursor: pointer;
        fill: $color-primary;
      }
    }

    @media (max-width: $max-mobile) {
      cursor: pointer;

      button {
        padding: 0 5px;

        &:focus {
          outline: none;
        }

        svg {
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
      }
    }

    @media (min-width: $min-not-mobile) {
      button {
        // position: absolute;
        // top: -0.3em;
        // right: -0.3em;
        // padding: 0;
      }
    }
  }
}