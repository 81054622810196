.visualization {
  &_container {
    position: relative;
    //Scalable font size
    font-size: 1em;
    font-size: 0.875em;
    border-right: 1px dotted gray;
    padding: 1.4em;
    &:last-child {
      border-right: none;
    }
  }
}
