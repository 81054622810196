@import "src/styles/_constants.scss";

.placeholderIcon{
    content:'';
}

.container{
    position: relative;
    display:flex;

    @media (min-width: $max-mobile){
        margin-right: -150px;
    }

    width: 15%;
    width:100%;
    height:100%;
    max-width: 150px;
    &_button{
        span{
            font-family: "Roboto",sans-serif;
            font-size:14px;
            display:inline-block;
            vertical-align: middle;
            position: absolute;
            transform: translate(-61%,-50%);
           
        }

        span:nth-child(2){
            left:85%;
            top:52%;
            width:20px;
            height:20px;
        }
        svg{
            width:100%;
            height:100%;
        }
        margin-top:auto;
        margin-bottom:auto;
        height: 45px;
        cursor: pointer;
        border: none;
        width:100%;
        background-color: transparent;
        position: relative;
    }
}