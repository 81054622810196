.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-wrap: nowrap;
  }
}

.lonnVisualization {
  &_kjonncontainer {
    height: 230px;
    display: flex;
    align-items: center;
    @media (max-width: 500px) {
      height: unset;
      display: flex;
      justify-content: center;
    }
    &_nographics {
      display: block;
      text-align: center;
      align-items: center;
      height: 230px;
    }
  }
  &_kjonn {
    &_nographics {
      height: 100%;
      display: flex;
      flex-direction: column;
      .lonnVisualization_kjonn_text_kjønn {
        margin: auto;
      }
      .lonnVisualization_kjonn_text_K,
      .lonnVisualization_kjonn_text_M,
      .lonnVisualization_kjonn_text {
        margin: auto;
      }
    }

    &_icon {
      svg {
        height: 1.5em;
      }
      margin-right: 5px;
    }
    &_text_K,
    &_text_M,
    &_text {
      font-weight: 600;

      svg {
        height: 15px;
        width: 15px;
        margin-left: 5px;
      }
    }

    &_text_K {
      color: #84012e;
      display: flex;
      &_iconContainer {
        background-color: #84012e;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        margin-top: 3px;
        margin-left: 5px;

        svg {
          height: 15px;
          margin-left: 3px;
          margin-bottom: 2px;
          path {
            fill: white;
          }
        }
      }
    }

    &_text_M {
      color: #1f72b6;
      display: flex;
      svg {
        margin-left: 2px !important;
        margin-top: 3px;
      }
    }
  }
}
