@import "src/styles/_constants.scss";

.row_style {
  display: flex;
  align-items: stretch;
  justify-content: center;
  &_padding {
    padding-bottom: 40px;
  }
  justify-content: flex-start;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow: -moz-scrollbars-none; /* Older version of Firefox */    
  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
    overflow: -moz-scrollbars-none;
  } 

@media (min-width: 1165px) {
  display: grid;
  grid-template-columns: repeat(5, 233px);
  justify-content: center;
  overflow-x: unset;
  }


  .empty_cells_placeholder {
    border-right: 1px dotted gray;
    &:last-child {
      border: none;
    }
    padding: 1.5em;
    color: gray;
    color: $color-global-text;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size:14px;
  }
  > * {
    flex: 0 0 $column-width;
    max-width: $column-width; // IE: really?
    @media (max-width: $max-mobile) {
      flex: 0 0 $column-width-mobile;
      max-width: $column-width-mobile; // IE: really?
      justify-content: flex-start;
    }
  }
}
