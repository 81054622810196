@import "src/styles/_constants.scss";

.container {
  text-align: center;
  border-right: 1px dotted gray;
  padding-top: 10px;
  margin-top: 30px;
  margin-bottom: 20px;

  &_title {
    word-break: break-word;
    font-size: 1.3em;
    font-size: 1.25em;
    margin-bottom: 20px;
    padding: 10px;
  }

  &_link {
    border: 2px solid $color-primary;
    border-radius: 22px;
    margin: auto auto 0;
    padding: 1.5em;
    padding-top: 0.4em;
    padding-bottom: 0.6em;
    color: black;
    // color: $color-global-text;
    text-decoration: none;
    font-weight: 500;

    &_icon {
      height: 15px;
      width: 15px;
      margin-bottom: -3px;
      padding-left: 5px;
      margin-left: 4px;

      path {
        fill: $color-primary;
      }
    }
  }

  &_childContainer {
    height: 170px;
  }
}