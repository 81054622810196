.tooltipcontainer{
    position: absolute;
    bottom: calc(100% + 16px);
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid white;
    min-width:10em;
    min-height:5em;
    max-width:25em;
    p{
        //width:90%;
        width: 235px;
        margin:.5em;
    }
    &::before {
        content: "";
        position: absolute;
        bottom: -14px;
        left: 50%;
        transform: translateX(-50%);
        border-style: solid;
        border-width: 14px 10px 0;
        border-color: rgb(51, 51, 51) transparent transparent;
    }
}

.tooltipicon{
    color: black;
    margin-left:.5em;
    
}

.tooltipwrapper {
    position: relative;
}
button{
    color: black;
    margin-left:.5em;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
}
