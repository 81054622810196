.nodata {
  &_container {
    //Scalable font size
    font-size: 1em;
    text-align: center;
    &_icon {
      img {
        height:3em;
      }
      margin-bottom: 0.5em;
      svg {
        height: 3em;
      }
    }

    &_text {
      font-size: 1.4em;
      font-size:1.143em;
    }
  }
}
