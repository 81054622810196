@import "../../styles/constants";
.buttonContainer{
    // background-color: $color-primary-light;
    // border-radius:50px;
    // padding:20px;
    // height:48px;
    &.expanded {
        background-color: #443c38;
        .header{
            color: #fff;
        }
    }

    // &:hover {
    //     box-shadow: 0px 10px 10px 0px #cecece;
    //   }
    display:flex;
    justify-content: flex-end;
    margin: 18px auto;
    max-width: calc(233px * 5);
    padding: 0 .5em;

    @media (min-width: $max-mobile ){
        justify-content: flex-start;
        margin-top:1em;
    }

    align-items: center;
    
    .buttonContent{
        align-items: center;
        background-color: $color-primary-light;
        border-radius:50px;
        cursor: pointer;
        display:flex;
        height:48px;
        padding:20px;

        &_expanded {
            background-color: #443c38;

            .header{
                color: #fff;
            }
        }
    }

    @media (max-width: $max-mobile) {
        justify-content: flex-end;
    }
}

.header{
    font-weight: 500;
    font-size:1.25em;
    font-size: 0.875em;
    color: #000;
    padding-left: 10px;
}

.head_icon{
    //margin-top:auto;
    //margin-bottom:auto;
    margin-left:5px;
    span{
        background-color: white;
        float: none;
        margin: 14px;
        display: inline-block;
    }
}

.containerContent{
    @media (min-width: $min-not-mobile) {
        display:flex;
    }
    padding: 1px;
    margin-top:-1px;
    font-size:0.875em;
    // justify-content: center;
    //background-color: #cecece;
    background-color: #443c38;
    color: #fff;
}

.optionsFirst{

    &_text{
        // padding-left:40px;
        // width:100%;
    }
}

.optionsFirst, .optionsSecond{
    @media (min-width: $min-not-mobile) {
        width:16%;
    }
    li{
        list-style: none;
    }
    label {
        display: flex;
        align-items: center;
        min-height: 48px;
    }
    input[type=radio] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: block;
        box-sizing: border-box;
        padding: 3px;
        min-width: 24px;
        min-height: 24px;
        margin-right: 8px;
        border: #fff solid 3px;
        border-radius: 50%;
        background-clip: content-box;

        &:checked {
            background-color: #fff;
        }
    }
}
