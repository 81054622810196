@import "src/styles/_constants.scss";
.frontpage { 
  &_searchcontainer{
    display:flex;
    justify-content: center;

    div:nth-of-type(2){
      width: 2em;
      height: 2em;
      width: 100%;
      height: auto;

      @media (max-width: $max-mobile){
        top:50px;
        width: 100%;
        height: auto;

        div {
          width:100%;
          height: auto;
        }
      }
    }

    div div svg {
        width:1.1em;
        height:1.1em;
        right:12px;
        @media (max-width: $max-mobile){
          top:23px;
        }
    }
  }
   
  &_undertitle{
    display:inline-block;
  }
  &_header {
    text-align: center;
    text-transform: uppercase;  // test
    // font-weight: 500; // test
    margin: 0;
    padding-top: 1.25em;

    p{
      margin-top: 0;
      margin-bottom:0;
      color: $color-primary;
    }
  }
  &_options {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    justify-content: center;

    &_searchbox {
      margin: .75em 2%;
      width: 20em;
      height:48px;
      // margin-top:0;

      @media (max-width: 767px){
        div div {
          top:15px;
          width: 2em;
          height: 2em;
        }
  
        div svg{
          width: 1.1em;
          height: 1.1em;
          right: 12px;
        }
      }

      input{
        height:48px;
      }
      @media (max-width: $max-mobile) {
        //width: 90%;
        width:95%;
        margin: auto;
        margin-bottom: 15px;
      }
    }
    a {
      margin: 1.25em 2%;
      // Fullscreen here
      width:12%; 
      width:100%;
      height:48px;
      max-width:340px;
      padding:10px;
      font-size:1.5em;
      font-size:1.25em;
      text-transform: uppercase;
      @media (max-width: $max-mobile) {
        // width: 80%;
        max-width: none;
        width: 95%;
        margin: auto;
        margin-top: 10px;
        padding: 12px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        font-size: 1em;
      }
    }
  }
}