.rectangle {
  stroke: black;
  &_filled {
    fill: black;
    stroke-width: 1;
    stroke: white;
  }
}

.female_bar {
  fill: #84012e;
}

.male_bar {
  fill: #267CC1;
}

.data_bar {
  fill: gray;
  opacity: 0.341;
}

.icon {
  stroke: #fff;
  &_woman {
    path {
      fill: #fff;
    }
  }
}
