@import "src/styles/_constants.scss";

.alphabetic {
  list-style: none;
  padding: 0;
  margin: 2em;
  margin-top: 1em; // test
  margin-left:auto;
  margin-right:auto;
  width:95%;
  @media (min-width: $max-mobile){
    width: 100%; // test
  } 
}

.alphabetic_headers {
  justify-content: center;
  display: flex;
  border-bottom: 1px solid black;
  margin-bottom: 1em;
  @media (max-width: $max-mobile) {
    border-bottom: none;
    margin-bottom: 0;
    h3 {
      //display:none;     // Hides the alphabet letters on mobile view
      
      margin-bottom: 6px;
    }
  }
}

.alphabetic_header[data-letter="A"]{    // Hides the letter A. 
  @media (max-width: $max-mobile){
    display:none;
  }
}
.alphabetic_header {
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: black;
  span {
    background-color: $color-primary;

    border-radius: 50%;
    display: block;
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    font-weight: 400;
    padding-top:2px; // test 
  }
}

.alphabetic_list {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  margin-bottom: 10px;
  &_container {
    justify-content: center;

    @media (min-width: $max-mobile){
      margin-left:auto;   // test 
      margin-right:auto;  // test 
      width:70%;          // test
      @media only screen and (min-width:768px) and (max-width:1334px){
        width:95%;
        //margin-left:0;
      }
    } 
  }

  &__item {
    width: 20%;

    @media only screen and (min-width:1800px) and (max-width:2002px){
      width:21%;
    }

    @media only screen and (min-width:1800px) and (max-width:1906px){
      width:22%;
    }

    @media only screen and (min-width:900px) and (max-width:1819px){
      width:30%;
    }

    @media only screen and (min-width:900px) and (max-width:984px){
     // width:35%;
    }

    @media only screen and (min-width:769px) and (max-width:984px){
      width:48%;
    }

    // @media only screen and (min-width:900px) and (max-width:1291px){
     // width:35%;
    // }
    // @media only screen and (min-width: 1800px) {
    //   width: 21%;
    // }

    // @media only screen and (min-width: 1000px) {
    //   width: 20%;
    // }
    @media only screen and (max-width: 900px) {
      width: 30%;
      width:47%;
    }
    @media only screen and (max-width: 600px) {
      width: 60%;
    }
    @media only screen and (max-width: $max-mobile) {
      width: 100%;
      margin-right:0;
    }

    word-break: break-word;
    margin-right: 1em;
    padding: 0.1em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    &_label {
      font-size:14px;
      @media (max-width: $max-mobile) {
        display: block;
        background-color: lightgrey;
        border-radius: 20px;
        margin-bottom: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-top:10px;     // test 
        padding-bottom:10px; // test

        input {
          visibility: hidden;
        }
      }
      &_selected {
        @media (max-width: $max-mobile) {
          background-color: $color-primary;
        }
      }
    }

    label,
    input {
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: .75rem;
    }
  }
}

.compare_section {
  &_row {
    background-color: #fbe5d0;
    justify-content: center;
    display: flex;
    margin-top: -.5em;
    padding: 20px 0;
    &_item {
      background-color: $color-primary;
      color: black;
      // color:$color-global-text;
      text-decoration: none;
      padding: 12px;
      border-radius: 22px;
      width: 10em;
      font-size:14px;
      font-family: "Roboto",sans-serif;
      text-align: center;
      @media (max-width: $max-mobile) {
        display: none;
      }
      svg {
        height: 1.2em;
        width: 1.2em;
        margin-bottom: -0.2em;
        margin-right: 5px;
      }
    }
  }
}

.mobile_row {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-bottom: 0px;// test
  @media (min-width: $min-not-mobile) {
    display: none;
  }
}

.mobile_back_btn,
.mobile_sammenlign_btn {
  border-radius: 22px;
  border: 1px solid $color-primary;
  text-decoration: none;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  color: black;
}
.mobile_sammenlign_btn {
  background-color: $color-primary;
  border: none;
  @media (min-width: $min-not-mobile) {
    display: none;
  }
  svg {
    height: 1.2em;
    margin-bottom: -0.2em;
    margin-right: 5px;
  }
  &_disabled {
    background-color: #cccccc;
  }
}

.mobile_search {
  // width: 80%;
  width:95%;
  padding-bottom: 20px;
  margin: auto;
  @media (min-width: $min-not-mobile) {
    display: none;
  }
  svg{
    top:13px !important;
  }
}
.sticky_header {
  position: sticky;
  top: 0px;
  z-index: 1010;
}

.header_content{
  display:flex;
}
