@import "src/styles/_constants.scss";
.container {
  z-index: 2;
  font-size: 1.6em;
  background-color: darkgray;
  padding: 10px;
  display: block;
  word-wrap: break-word;
  text-align: center;

  @media (min-width: $min-not-mobile) {
    padding: 10px 0;
  }
  &_showAll {
    margin-right: 20px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
  &_alphabet {
    @media (min-width: $min-not-mobile) {
      display: flex;
      width: 95%;
      margin: 0 auto;
    }
    &_letter {
      margin-left: 4px;
      margin-right: 4px;
      font-weight: bold;
      cursor: pointer;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &_unselected {
        color: grey;
        cursor: auto;
      }
      &_selected {
        color: $color-primary;
      }
      @media (min-width: $min-not-mobile) {
        flex-grow: 1;
      }
    }
  }
}
