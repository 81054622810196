@import "src/styles/_constants.scss";
.flex_item {
  margin: 0 auto;
  max-width: calc(233px * 5);
  padding: 0 .5rem;
  &.title {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    max-width: 30%;
  }

  &.item {
    max-width: 30%;

    &_title {
      vertical-align: middle;
      text-transform: uppercase;
      //margin-left: 10px;
      font-size: 1.8em;
      font-size: 1.875em;
      @media (min-width: $min-not-mobile) {
        font-size: 2em;
        font-size:1.25em;
        font-size: 1.875em;
        //margin-left: 50px;
      }

      &_withDesc {
        margin-bottom: 0;
      }
    }
    &_description {
      margin-top: 0;
      font-weight: 500;
      //margin-left: 10px;
      font-size:0.875em;
      @media (min-width: $min-not-mobile) {
        font-size: 1.2em;
        font-size: 0.875em;
        //margin-left: 50px;
      }
    }
  }
}
