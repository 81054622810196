@import "src/styles/_constants.scss";

.searchbox {
  position: relative;
  font-size: 1.2em;

  &_container {
    // margin-top:10px; fjernetfor testing
    height: 48px;

    @media (max-width:$max-mobile) {
      margin-top: 10px;
    }

    @media (min-width: $max-mobile) {
      display: flex;
      align-items: center;
    }

    &_input {
      // height: 1.9em;
      height: 2.24em;
      height: 100%;
      border-radius: 22px;
      border: 2px solid $color-primary;
      // padding: 4px 15px;
      padding: 10px 15px;
      width: 100%;
      font-size: 1rem;
      font-family: "Roboto", sans-serif;
      // color: $color-global-text;

      &:focus {
        outline: none;
        border-color: $color-primary;
      }
    }

    &_iconcontainer {
      background-color: $color-primary;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      right: 4px;
      position: absolute;
      top: 5px;

      @media (max-width: $max-mobile) {
        top: 5px;
        position: absolute;
        right: 4px;
        // width:2em;
        // height:2em;
      }
    }

    svg {
      position: absolute;
      height: 1em;
      width: 1em;
      fill: black;

      @media (max-width: $max-mobile) {
        top: 15px;
        height: 1em;
        width: 1em;
      }

      right: 13px;
      // cursor: pointer;
    }
  }

  &_dropdown {
    // &_hidden {
    //   position: initial;
    //   height: 200px;
    //   width: 400px;
    // }
    position: absolute;
    transform: translate3d(0, 0, 1px); // Safari hack, safari ignored the z-index
    z-index: 1022;
    top: 35px;
    width: 278px;

    @media (max-width: $max-mobile) {
      max-width: 100%;
    }

    background-color: white;
    padding: 0.1em 1em 1em 1em;
    border: 1px solid black;

    &_help {
      color: gray;
      color: #555555;
      font-weight: bold;
      text-transform: uppercase;

      @media (max-width: 414px) {
        font-size: 0.89em;
      }
    }

    transition: opacity 0.5s ease-in-out;

    &_hide {
      // z-index: -1000;
      opacity: 0;
      pointer-events: none;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin: 0;
        padding: 0;
      }
    }

    &_header {
      text-transform: uppercase;
      background-color: lightgrey;
      margin: 0;
      padding: 0;
    }

    button {
      border: none;
      margin: 0;
      padding: 0.2em;
      width: 100%;
      text-align: left;
      background: transparent;
      color: inherit;
      font: inherit;

      &:hover {
        background-color: antiquewhite;
      }

      &.active {
        color: $color-primary;
      }
    }

    &_inline {
      position: unset;
      z-index: 1050;
      border: none;
      margin-top: 20px;
    }
  }
}