@import "src/styles/_constants.scss";
.breadcrumb {
  @media(min-width: $max-mobile){
    a {
      font-size: 1.5em;
      font-size:20px;
    }
  }
  display: flex;
  justify-content: center;
  > * {
    margin: 2%;
  }

  &_mobilecontainer{
    background-color:#fbe5d0;
    border-color:#fbe5d0;
    border-radius:200px;
    height: 48px;
    text-align: center;
    width: 17.5%;

    a {
      border:2px solid transparent;
      color:#333;
      display: block;
      font-family: "Roboto",sans-serif;
      font-size:20px;
      text-decoration: none;
      line-height: 44px;
      
    }
    @media (max-width: 1000px){
        a {
          font-size:14px;
        }
    }

  }
  @media (max-width: $max-mobile) {
    &_mobilecontainer{
      width:100%;
      a {
        display: block;
        width: 190px;
        width:100%;
        font-size:0.875em;
      }
    }

  }
  .SearchBox {
    display: none;
  }
  @media (min-width: $min-with-separate-search-box) {
    .SearchBox {
      display: block;
    }
  }
}
