@import "src/styles/_constants.scss";

.btn {
  text-decoration: none;
  display: inline-block;
  padding: 1em;
}

.btn_primary {
  color: white;
  background-color: lighten($color: #000000, $amount: 0.1);
}

.button_row {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px;
  -ms-flex-pack: center;
  align-items: center;

  @media (max-width: $max-mobile) {
    display: none;
  }

  &_content {
    align-content: center;
  }

  a {
    width: 150px;
    margin-right: 20px;
    text-align: center;
    font-weight: 500;

    &:last-child {
      margin-right: 0;
    }
  }
}

.compare_container {
  width: 100%;
  display: grid;
  margin-top: 5px;
  grid-template-columns: 50% 50%;
  margin-bottom: 10px;

  @media (max-width: $max-mobile) {
    display: none;
  }

}

.compare_buttoncontainer {
  position: relative;
  height: 3.1em;
}

.compare_titlecontainer {
  border: none;

}

.compare_title {
  margin: auto;
  // margin-right:0; // test
  text-align: end; // test 
  // margin-top: 40px; removed for test
  margin-top: 11px;
  margin-right: 10px;
  text-transform: uppercase;

  @media (max-width: $max-mobile) {
    display: none;
  }
}

.btn_selected {
  background-color: $color-primary;
  color: black;
}