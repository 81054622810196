@import "src/styles/_constants.scss";

.selection_buttondesktop,
.selection_buttonmobile {
    position: relative;
    //width:95%;
    background-color: transparent;
    border: none;
    height: 2.24em;
    padding: 10px;
    font-size: 1em;
    margin: 10px auto auto auto;
    height: 100%;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    color: #e06c07;

    p {
        padding-right: 1rem;
    }
}

.selection_buttondesktop {
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 600;
    margin-top: 0;
    max-width: 400px;
    padding-top: 5px;
    padding-left: 2px;
    position: absolute;
    text-transform: uppercase;

    svg {
        display: inline-block;
        width: 20px;
        height: 20px;
    }

    &_options {
        margin-bottom: 0;
        font-size: 1.5em;
        display: flex;
        padding-top: 10px;
        padding-top: 8.5px;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
    }

    &_active {
        position: absolute;
        height: 5em;
        z-index: 1011;
        background-color: white;
        top: 0;

        .innhold_options {
            display: flex;
            margin-bottom: 10px;
        }

        svg {
            transform: rotate(180deg);
            width: 20px;
            height: 20px;
        }
    }

    p {
        margin-right: auto;
        margin-bottom: 0;
        margin-top: 0;
        cursor: pointer;

        a {
            text-decoration: none;
            color: #e06c07;
        }
    }
}

.selection_buttonmobile {
    width: 95%;

    svg {
        display: inline-block;
        width: 20px;
        height: 20px;
    }

    p {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        margin-top: 0;
        cursor: pointer;

        a {
            text-decoration: none;
            color: #E7772E;
        }
    }

    &_options {
        margin-bottom: 0;
        display: flex;
    }

    &_active {
        position: absolute;
        height: 5em;
        z-index: 1;
        background-color: white;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        &_options {
            margin-bottom: 10px;
            display: flex;
        }

        svg {
            transform: rotate(180deg);
            width: 20px;
            height: 20px;
        }
    }
}