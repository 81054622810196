@import "src/styles/_constants.scss";

.ComparisonPage {
  .breadcrumb {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 1.4em;
    &_link {
      flex-flow: row wrap;
      display: flex;
      text-decoration: none;
      color: black;
      svg {
        height: 1em;
        margin-right: 10px;
        margin-top: 6px;
      }
    }
  }
  //.flex_container {
  //  @media (min-width: $min-with-separate-search-box) {
  //    width: 80%;
  //    margin: auto;
  //  }
  //}

  .flex_item {
    width: 100%;
    &.title {
      font-size: 1.3em;
      font-weight: bold;
      text-align: center;
      justify-content: center;
      max-width: 30%;
    }

    &.item_title {
      font-size: 1.3em;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      background-color: $color-primary;
    }

    &.item {
      max-width: 30%;
    }
  }

  .flex_container_row {
    width: 100%;
    display: flex;
    justify-content: center;
    &.titlerow {
      width: 100%;
      background-color: $color-primary;
      margin-bottom: 0.2em;
    }
  }
}
