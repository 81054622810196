@import "src/styles/_constants.scss";

.container {
  @media (max-width: $max-mobile) {
    display: none;
  }

  &.expanded {
    background-color: #443c38;

    .container_head_infotext {
      color: #fff;
    }
  }

  //background-color: #cecece;
  margin: 0 auto 20px;
  max-width: calc(233px * 5);
  padding: 0 .5em;

  &_filtercontainer {

    &_divContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
    }


    @media (min-width: $max-mobile ) {
      justify-content: flex-start;
      // margin-top:1em;
    }

    &_content {
      align-items: center;
      background-color: $color-primary-light;
      border-radius: 50px;
      cursor: pointer;
      display: flex;
      height: 48px;
      padding: 20px;

      &_expanded {
        background-color: #443c38;

        div:first-of-type {
          color: #fff;
        }
      }

      &_head {
        font-weight: 500;
        font-size: 1.25em;
        font-size: 0.875em;

        // padding: 10px;
        &_infotext {
          font-size: 0.875em;
          color: #000;
        }

        &_upper {
          text-transform: uppercase;
        }

        &_icon {
          margin-left: 5px;

          span {
            background-color: white;
            float: none;
            margin-left: 8px;
            display: inline-block;
          }
        }

        &.expanded {
          background-color: #443c38;
        }
      }
    }
  }

  &_content,
  &_content_arbeidsledighet {
    //background-color: #cecece;
    background-color: #443c38;
    color: #fff;
    padding: 10px;
    padding-left: 20px;
    padding-top: 0;

    &>div:first-child {
      display: flex;
      justify-content: center;
    }

    ul {
      width: 16%;
    }

    &_arbeidsledighet {
      ul {
        width: 30%;
      }
    }
  }
}