.container {
  //height: 360px;
  //height: 200px;

  &_filter {
    display:flex;
    margin: 0 auto;
    max-width: calc(233px * 5);
    &_container{
      display:flex;
      padding-left: 10px;

      @media screen and (max-width: 768px){
        padding-left:17em;
      }
      @media screen and (max-width: 515px){
        padding-left:1.4em;
      }
      &_text{
        font-size: 20px;
        font-size: 1.0em;
        font-size:0.875em;
        align-self: center;
        font-weight: bold;
        text-transform: uppercase;
       
      }
      &_icon{
        margin-left:0.3em;
        //width:28px;
        //height:28px;
      }
    }
  }
  &_header {
    font-weight: 600;
    font-weight: bold;
    font-size:14px;
  }
  &_andel {
    margin-bottom: 20px;
  }
  &_antall {
    display: flex;
    width: 100%;
    svg {
      height: 1.5em;
      margin-right: 10px;
    }
  }
  &_noData {
    font-size:14px;
    margin-top:0.4em;
    margin-bottom: 20px;
  }
}
