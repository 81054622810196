@import "src/styles/_constants.scss";
.header {
  margin-left: 10px;
  @media (min-width: $min-not-mobile) {
    margin: 0 auto;
    max-width: calc(233px * 5);
    padding: 0 .5em;
  }
  h2 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.8em;
    font-size: 1.875em;
    @media (min-width: $min-not-mobile) {
      font-size: 2em;
      font-size:1.25em;
      font-size: 1.875em;
    }
  }
  h4 {
    margin-top: 0;
    font-weight: 500;
    font-size:0.875em;
    @media (min-width: $min-not-mobile) {
      font-size: 1.25em;
      font-size:0.875em;
    }
  }
}
