@import "src/styles/_constants.scss";

.container{
    background-color: $color-primary;
}

.content{
    display:flex;
    width:50vw;
    margin-left:auto;
    margin-right:auto;
    padding: 20px 0 20px 0;

    div:first-of-type{
        margin-right:1em;
    }
}

.closeabout{
    border: 2px solid lightsalmon;
    border-radius:30px !important;

    &::before{
        content: "X";
        margin-right:0.5em;
    }
}

@media (max-width: $max-mobile) {
    .content{
        padding: 10px;
        flex-wrap: wrap;
        width:100vw;
        div:first-of-type {
            margin-bottom:1em;
            margin-right:0;
        }
    }
}

