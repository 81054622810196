@import "src/styles/_constants.scss";

.radio_wrapper {
  display: flex;
  align-items: center;
  min-height: 48px;

  label {
    display: flex;
    align-items: center;
  }

  input[type=radio] {
    min-width: 24px;
    min-height: 24px;
  }
}
