@import "src/styles/_constants.scss";

.button {
  display: block;
  border-radius: 200px;
  padding: 0.4em 0.9em;
  font-size: 0.8em;
  text-decoration: none;
  //border: 2px solid transparent;
  text-align: center;
  font-family: "Roboto",sans-serif;
  font-weight: bold;
  min-height:48px;
  &_dark {
    background-color: $color-primary;
    color: black;
  }
  &_light {
    background-color: $color-primary;
    color: $color-global-text;
    color:black;
  }
  &_selected {
    background-color: $color-primary-light;
    border-color: $color-primary-light;
    // color:black;
  }
}
